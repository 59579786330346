<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as EMAIL_TYPOLOGY_TYPES } from './store'
import { ROUTES as EMAIL_TYPOLOGY_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import EmailTypologyTable from './EmailTypologyTable'
import EmailTypologyCommonFilters from './EmailTypologyCommonFilters'
import EmailTypologyEdit from './EmailTypologyEdit.vue'

export default {
  name: 'EmailTypologyList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': EmailTypologyTable,
    'common-filters': EmailTypologyCommonFilters,
    'component-detail': EmailTypologyEdit,
    'component-edit': EmailTypologyEdit
  },
  data () {
    return {
      EMAIL_TYPOLOGY_ROUTES,
      title: this.$t('Email typologies'),
      rolePerm: ['organization_emailtypology_list'],
      actionEnablePermission: ['organization_emailtypology_enable'],
      actionDisablePermission: ['organization_emailtypology_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 4 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 4 },
        { key: 'filter__slug__icontains', placeholder: this.$t('Slug'), type: 'text', col: 4 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...EMAIL_TYPOLOGY_TYPES.GENERIC.organization.emailtypology.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.EMAIL_TYPOLOGY_ROUTES.EMAIL_TYPOLOGY_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_emailtypology_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: EMAIL_TYPOLOGY_TYPES.GENERIC.organization.emailtypology.LIST.MUTATIONS.setSelectedList,
      setSelectAll: EMAIL_TYPOLOGY_TYPES.GENERIC.organization.emailtypology.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...EMAIL_TYPOLOGY_TYPES.GENERIC.organization.emailtypology.LIST.ACTIONS
    })
  }
}
</script>
